<template>
  <div>
    <Toolbar :handle-reset="resetForm" :handle-submit="onSendForm"></Toolbar>
    <InvoiceForm :errors="violations" :values="item" ref="createForm" :handle-message="showMessage"/>
    <Loading :visible="isLoading"/>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {createHelpers} from 'vuex-map-fields';
import InvoiceForm from '../../components/invoice/Form';
import Loading from '../../components/Loading';
import Toolbar from '../../components/Toolbar';
import CreateMixin from '../../mixins/CreateMixin';

const servicePrefix = 'Invoice';

const {mapFields} = createHelpers({
  getterType: 'invoice/getField',
  mutationType: 'invoice/updateField'
});

export default {
  name: 'InvoiceCreate',
  servicePrefix,
  mixins: [CreateMixin],
  components: {
    Loading,
    Toolbar,
    InvoiceForm
  },
  data() {
    return {
      item: {
        positions: []
      }
    };
  },
  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'violations']),
    isInvoice() {
      return this.$route.meta.type === "invoice";
    },
    pageTitle() {
      return this.isInvoice ? this.$t("Invoices") : this.$t("Credits");
    },
  },
  methods: {
    ...mapActions('invoice', ['create', 'reset']),
    getItemSubs() {
      return {
        profile: {}
      }
    }
  },
  created() {
    this.$options.servicePrefix = this.isInvoice ? "Invoice" : "Credit";
  }
};
</script>
